// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Maven Pro', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue:    #0712a6;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #ff3860;
$orange:  #db5800;
$yellow:  #ffc107;
$green:   #198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

// Grayscale
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

// Brands
$primary:       $blue;
$secondary:     $orange;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

// Hero Image
.hero-background {
  background-image: url("/images/welcome-hero-background.jpg");
  background-size: 100% 100%;
}

// Carousel Arrow Colour
$carousel-control-color: $orange;

// Carousel Arrow width
$carousel-control-icon-width:        4rem;

a.dropdown-item :hover {
  color: $orange;
}

// Carousel Left Arrow
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23db5800' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important;
}

// Carousel Right Arrow
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23db5800' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e") !important;
}